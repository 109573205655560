<template>
  <div class="box">
    <!-- 循环订单信息 -->
    <div v-for="(item, index) in  list " :key="index" class="bgc" @click="goOrderDetail(item)">
      <div class="flex jcsb">
        <div style="  font-size: 3.733vw;">订单编号:{{ item.orderNo }}</div>
        <div v-if="item.orderDetail" :class="item.status == '0' ? 'statusred' : 'statusblack'">{{ getStatus(item.status)
          }}</div>
      </div>
      <!-- 循环商品信息 -->
      <div v-if="item.orderDetail" class="flex a-i-fs" style="margin-top: 0.8rem;">
        <img :src="getPic(item)" alt="" class="img" />
        <div style="display: flex; flex-direction: column;flex: 1;">
          <div class="textTitle">
            {{ item.productName }}
            <p class="textcontext">{{ item.skuName }}</p>
          </div>
          <div class="t-a-r" style="margin-top: 0.5rem;color: #999;">x {{ item.productNum }}</div>
        </div>
      </div>
      <div v-else class="flex a-i-fs" style="margin-top: 0.8rem;" v-for="(item2, index) in item.productSubOrderList">
        <img :src="getPic(item, item2)" alt="" class="img" />
        <div style="display: flex; flex-direction: column;flex: 1;">
          <div class="textTitle">
            <div class="disp">
              <div> {{ item2.productName }} </div>
              <div :class="item.status == '0' ? 'statusred' : 'statusblack'">{{ getStatus(item2.status) }}</div>
            </div>
            <p class="textcontext">{{ item2.skuName }}</p>
          </div>
          <div class="t-a-r" style="margin-top: 0.5rem;color: #999;">x {{ item2.emptionNum }}</div>
        </div>
      </div>
      <div class="price">
        <div>
        </div>
        <div>
          <div v-if="item.status !== '4' && item.status !== '5'" class="priceItem">
            <span v-if="item.status == '0'" style="font-size: 0.9rem;">需付款：</span>
            <span v-else style="font-size: 0.9rem;">实付款：</span>
            <div v-if="item.originalPrice" style="text-align: end; ">
              <span style="font-size: 1.1rem;font-weight: 600;">{{ item.originalPrice }}</span>
              <span style="font-size: 0.8rem;margin-top: 0.2rem;">点</span>
            </div>
          </div>
          <div >
            <div v-if="item.userRefundAmount" style="text-align: end; ">
              <span style="font-size: 0.8rem;">（ 已退{{ item.userRefundAmount }}</span>
              <span style="font-size: 0.8rem;margin-top: 0.2rem;">点 ）</span>
            </div>
          </div>
        </div>

      </div>
      <!-- 收货地址信息 -->
      <div v-if="item.status === '0'" class="flex jce" style="margin-top: 1rem;">
        <div class="tag" style="border: 1px solid #aea9a9; ; border-radius: 1rem; padding:  0 0.8rem ;  "
          @click.stop="cancelOrder(index)">
          取消订单
        </div>
        <div class="tag"
          style="background-color: #D23B2E; border-radius: 1rem; padding:   0 0.8rem ; color: #EEE;margin-left: 0.5rem; "
          @click.stop="goPay(index)">
          去支付
        </div>
      </div>
      <div v-if="item?.productSubOrderList.length === 1 && item?.express_no" class="flex jce" style="margin-top: 1rem;">
        <div v-if="item.express_no" class="tag" style="border: 1px solid #aea9a9; ; padding:  0 0.8rem;  "
          @click.stop=" goLogistics(index)">
          查看物流
        </div>
        <div v-if="item.express_no && item.status == '10'" class="tag"
          style="background-color: #D23B2E; border-radius: 1rem; padding: 0 0.8rem ; color: #EEE;margin-left: 0.5rem; "
          @click.stop=" onSureget(index)">
          确认收货
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CakeOrder",
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {
    getPic(str, st2) {
      if (str.orderDetail) {
        return JSON.parse(str.orderDetail).moviePicUrl
      } else {
        return st2.url
      }

    },
    //确认收货
    onSureget(index) {
      this.$emit('Sureget', index)

    },
    // 查看物流
    goLogistics(index) {
      this.$emit('Logistics', index)
    },
    //去支付
    goPay(index) {
      this.$emit('goPay', index)
    },
    //取消订单
    cancelOrder(val) {
      this.$emit('onCancelOrder', val)
    },
    goOrderDetail(val) {
      if (val.orderDetail) {
        this.$router.push({
          path: "/OrderDetail",
          query: {
            orderId: val.id,
            qmmOrderId: val.tripartiteOrderNo,
            status: val.status,
            extraPay: val.extraPay,
            orderNo: val.orderNo,
          },
        });
      } else {
        this.$router.push({
          path: "/CakaOrderDetail",
          query: {
            orderNo: val.orderNo,
            status: val.status,
            orderType: val.productType == '3' ? "sf-shop" : 'zy-shop',
          },
        });
      }

    },
    //0待支付 1待发货 2已发货 3已完成 4已关闭 5超时
    getStatus(val) {
      if (val == '0') {
        return '待支付'
      } else if (val == '1') {
        return '待发货'
      } else if (val == '2') {
        return '已发货'
      } else if (val == '3') {
        return '已完成'
      } else if (val == '4') {
        return '已取消'
      } else if (val == '5') {
        return '已超时'
      } else if (val == '6') {
        return '出票中'
      } else if (val == '7') {
        return '出票成功'
      } else if (val == '8') {
        return '出票失败'
      } else if (val == '10') {
        return '待收货'
      } else if (val == '11') {
        return '退货退款'
      } else if (val == '12') {
        return '更换商品'
      } else if (val == '13') {
        return '退款'
      } else if (val == '14') {
        return '换货'
      } else if (val == '15') {
        return '已关闭'
      } else if (val == '16') {
        return '待出餐'
      } else if (val == '17') {
        return '已出餐'
      } else if (val == '18') {
        return '出餐失败'
      } else if (val == '19') {
        return '充值失败'
      } else if (val == '20') {
        return '部分出餐'
      } else if (val == '21') {
        return '充值中'
      } else if (val == '22') {
        return '充值成功'
      }
    }

  },
  mounted() {
    // console.log(this.list);
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.box {
  background-color: #f8f8f8;

  .img {
    width: 6rem;
    height: 7rem;
    border-radius: 0.5rem;
    object-fit: cover;
  }

  .price {
    min-width: 3.5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    color: #d23b2e;
  }

  .btnBox {
    width: 60%;
    display: flex;
    justify-content: end;
  }

  .priceItem {
    display: flex;
    flex-direction: row;
    justify-content: end;
    font-weight: 600;
    color: #d23b2e;
  }

  .bgc {
    background-color: #fff;
    border-radius: 0.5rem;
    margin: 1rem 1.1rem 0 1.1rem;
    padding: 1rem;
  }
}

.textTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 3rem;
  font-size: 3.733vw;
  padding: 0.5rem 0 0 0.5rem;

  .disp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.textcontext {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #666;
  font-size: 0.85rem;
  padding: 0.5rem 0.5rem 0 0;
}

.tag {
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.85rem;
  border-radius: 1rem;
}

.statusblack {

  font-weight: 600;
  min-width: 3rem;
  font-size: 3.733vw;
}

.statusred {
  color: #d23b2e;
  font-size: 3.733vw;
  min-width: 3rem;
  font-weight: 600;
}
</style>